<template>
  <cc-view
    :id="id"
    :has-full-width="false"
    >
    <cc-dialog-wrapper>
      <template #dialog>
        <cc-alert id="alert" />
      </template>
    </cc-dialog-wrapper>

    <cc-earth v-if="true" />

    <cc-public-goal-card
      v-if="!hasOpenedPublicGoal"
      />

    <cc-public-goal-progress-card
      v-if="hasOpenedPublicGoal"
      class="pb-4"
      link="public-goal-progress"
      />

    <cc-climate-day-challenge-card v-if="false" />

    <cc-host-app-EOL-reminder-card />

    <cc-email-verification-card v-if="true" />

    <cc-company-challenge-reminder-card v-if="true" />

    <cc-verify-card v-if="true" />

    <cc-news-item-highlight-card v-if="true" />

    <cc-recent-trips v-if="true" />

    <cc-getting-started-card v-if="true" />

    <cc-climate-change-card v-if="true" />

    <cc-co2-overview class="pb-4" />

    <cc-journeys-card v-if="false" />

    <cc-news-feed-card v-if="hasNewsFeed" />

    <cc-share-card v-if="true" />

    <cc-location-permission-modal
      v-if="isLocationPermissionModalDisplayed"
      :data="{ permissionModalType }"
      :after-close="
        motionCheckRequired
          ? checkMotionPermission
          : setAppState(['didFinishPermissionChecks', true])
      "
      @close-modal="isLocationPermissionModalDisplayed = false"
      />

    <cc-motion-permission-modal
      v-if="isMotionPermissionModalDisplayed"
      :data="{ permissionModalType }"
      :after-close="() => setAppState(['didFinishPermissionChecks', true])"
      @close-modal="isMotionPermissionModalDisplayed = false"
      />

    <div v-if="false">
      <div
        v-for="i in 8"
        :key="i"
        >
        <cc-card class="p-4">
          <template #body>
            {{ uiState.tabBarHeight }}
            {{ uiState.navigationBarHeight }}
            {{ uiState.uiControlsHeight }}
            <br>
            {{ i }}
          </template>
        </cc-card>
      </div>
    </div>
  </cc-view>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { isEmpty } from 'lodash';

import viewScrollMemorizer from '@/mixins/viewScrollMemorizer';
import initialModals from '@/mixins/initialModals';

import ccView from '@/components/constructs/View.vue';
import ccCard from '@/components/constructs/Card.vue';

import ccDialogWrapper from '@/components/DialogWrapper.vue';
import ccAlert from '@/components/Alert.vue';
import ccEarth from '@/components/Earth.vue';
import ccPublicGoalCard from '@/components/footprint/PublicGoalCard.vue';
import ccVerifyCard from '@/components/footprint/VerifyCard.vue';
import ccGettingStartedCard from '@/components/footprint/GettingStartedCard.vue';
import ccClimateChangeCard from '@/components/footprint/ClimateChangeCard.vue';
import ccCo2Overview from '@/components/footprint/Co2Overview.vue';
import ccJourneysCard from '@/components/footprint/JourneysCard.vue';
import ccNewsFeedCard from '@/components/footprint/NewsFeedCard.vue';
import ccShareCard from '@/components/footprint/ShareCard.vue';
import ccClimateDayChallengeCard from '@/components/footprint/ClimateDayChallengeCard.vue';
import ccCompanyChallengeReminderCard from '@/components/footprint/CompanyChallengeReminderCard.vue';
import ccEmailVerificationCard from '@/components/footprint/EmailVerificationCard.vue';
import ccHostAppEOLReminderCard from '@/components/footprint/HostAppEOLReminder.vue';
import ccNewsItemHighlightCard from '@/components/footprint/NewsItemHighlightCard.vue';

import ccLocationPermissionModal from '@/components/modals/LocationPermissionModal.vue';
import ccMotionPermissionModal from '@/components/modals/MotionPermissionModal.vue';
import ccRecentTrips from '@/components/footprint/RecentTrips.vue';
import ccPublicGoalProgressCard from '@/components/public-goal/PublicGoalProgressCard.vue';

export default {
  name: 'Footprint',
  components: {
    ccView,
    ccCard,
    ccDialogWrapper,
    ccAlert,
    ccEarth,
    ccPublicGoalCard,
    ccPublicGoalProgressCard,
    ccVerifyCard,
    ccGettingStartedCard,
    ccClimateChangeCard,
    ccRecentTrips,
    ccCo2Overview,
    ccJourneysCard,
    ccNewsFeedCard,
    ccShareCard,
    ccClimateDayChallengeCard,
    ccCompanyChallengeReminderCard,
    ccEmailVerificationCard,
    ccLocationPermissionModal,
    ccMotionPermissionModal,
    ccHostAppEOLReminderCard,
    ccNewsItemHighlightCard,
  },
  mixins: [viewScrollMemorizer, initialModals],
  data() {
    return {
      id: 'footprint',
    };
  },
  computed: {
    ...mapState({
      appState: state => state.common.appState,
      timeline: state => state.trips.timeline,
      user: state => state.user.user,
      userState: state => state.user,
      wallets: state => state.user.wallets,
      messages: state => state.social.messages,
      notificationsQueue: state => state.social.notificationsQueue,
    }),
    ...mapGetters({
      userIsVerified: 'user/userIsVerified',
      getCantonPreference: 'user/getCantonPreference',
      areTripsFetching: 'common/areTripsFetching',
      hasNewsFeed: 'social/hasNewsFeed',
      hasTappedNotification: 'social/hasTappedNotification',
      getNotificationHref: 'social/getNotificationHref',
      getTappedNotification: 'social/getTappedNotification',
      getUnreadMessages: 'social/getUnreadMessages',
      hasFeatureTagShop: 'user/hasFeatureTagShop',
      hasFeatureTagWallet: 'user/hasFeatureTagWallet',
      hasOpenedPublicGoal: 'user/hasOpenedPublicGoal',
      isCompanyUser: 'user/isCompanyUser',
    }),
  },
  watch: {
    /**
     * `timezoneOffset` is originally set in `user/addUserPreferences` in user profile
     * here we check if utcOffset has changed since last login and if so we update user profile
     * But I do not know who is using this value, perhaps backend?
     * Check with @thiago or @ayres if still in use.
     */
    // eslint-disable-next-line func-names
    'user.preferences.timezoneOffset': function (val) {
      const utcOffset = this.$moment().utcOffset();
      if (!val && val !== utcOffset) {
        this.$store.dispatch(
          'user/setUtcOffset',

          { utcOffset },
        );
      }
    },
  },
  async mounted() {
    this.$log.info('Query params:', this.$route.query);
    if (this.$route.query) {
      const { query } = this.$route;
        const timeFrame = query?.timeFrame;
        if (timeFrame) {
          this.setAppState(['queryDate', Date.now()]);
          this.setAppState(['filter', timeFrame]);
          this.$router.replace({ name: 'footprint' });
        }
    }

    await this.$store.dispatch('social/getMessages');

    if (this.hasFeatureTagWallet) {
      await this.$store.dispatch('user/getWallets');
    }

    if (!this.hasNewsFeed) {
      await this.$store.dispatch('social/getNewsFeed');
    }

    if (this.appState.userLang !== this.appState.newsFeedLang) {
      await this.$store.dispatch('social/getNewsFeed');
    }

    /**
     * Following call sends an event to the challenge processor
     * which in return processes all running player challenges.
     * And will look for available/auto-subscrible challenges for the player.
     * If we don't call this before user looks at Challenges tab, then
     * user might see empty active challenges or challenges hasn't been processed yet
     */
    await this.$store.dispatch('achievements/getPlayerChallenges');


    /**
     * This handles the notifications tapped outside of the app (Android/iOS Notification Center etc.)
     * There are two flows.
     * 1. Launching the app from scratch, thus passing through Home/Splash screen
     * 2. App is already launched and on the background.
     *
     * Flow 2 handler is in `App.vue`, watching the `hasTappedNotification` and if route
     * is not `home`
     *
     * Flow 1 handler below. When app is being launched, we want to wait for
     * splash screen to be seen and after we land on footprint view then we handle the notification.
     * This behaviour can be changed but currently feels alright.
     */
    if (this.hasTappedNotification) {
      this.$store.dispatch('social/handleTappedNotification', {
        vm: this,
      });
    }


    /**
     * We do `watch` here to cover cases where user location permission is given on this view
     */
    this.$watch('appState.locationPermission', async (val) => {
      if (val === 'always' && isEmpty(this.userState.userLocation)) {
        await this.$store.dispatch('user/fetchUserLocation');
      }
    }, { immediate: true });
  },
};
</script>
